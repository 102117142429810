.loading-animation {
    display: flex;
    align-items: center;
  }
  
  @keyframes loading-animation-move {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  