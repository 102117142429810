.fade-animation {
    animation: fadeIn 0.2s ease-in-out;
}

.fade-animation-slow {
    animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}